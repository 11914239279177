import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  SubmitButton,
  TextareaField,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { playerSelfExclusionDetailsFragment } from '@/fragments/PlayerSelfExclusionDetails';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  CancelPlayerSelfExclusionMutation,
  CancelPlayerSelfExclusionMutationVariables,
} from './__generated__/CancelPlayerSelfExclusionMutation';
import { PlayerCancelSelfExclusionFormStaticQuery } from './__generated__/PlayerCancelSelfExclusionFormStaticQuery';

const query = graphql`
  query PlayerCancelSelfExclusionFormStaticQuery {
    sanityPlayerCancelSelfExclusionForm {
      title {
        ...LocaleString
      }
      commentLabel {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const cancelSelfExclusionMutation = gql`
  mutation CancelPlayerSelfExclusionMutation(
    $playerId: ID!
    $comment: String!
  ) {
    cancelSelfExclusionV3(playerId: $playerId, comment: $comment) {
      id
      ...PlayerSelfExclusionDetails
    }
  }
  ${playerSelfExclusionDetailsFragment}
`;

const PlayerCancelSelfExclusionForm: FC<{ playerId: string }> = ({
  playerId,
}) => {
  const staticData = useStaticQuery<PlayerCancelSelfExclusionFormStaticQuery>(
    query,
  );

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerCancelSelfExclusionForm;

  const [cancelSelfExclusionState, cancelSelfExclusion] = useMutation<
    CancelPlayerSelfExclusionMutation,
    CancelPlayerSelfExclusionMutationVariables
  >(cancelSelfExclusionMutation);

  const defaultValues = {
    comment: '',
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (playerId) {
      cancelSelfExclusion({
        playerId,
        comment: values.comment,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!form || !defaultValues) {
    return null;
  }

  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-1 gap-6"
        >
          <TextareaField
            title={t(form.commentLabel)}
            name="comment"
            id="PlayerCancelSelfExclusionForm__comment"
            rows={3}
          />
          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={cancelSelfExclusionState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerCancelSelfExclusionForm;
